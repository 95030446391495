import React from 'react';

import { useCart } from '../../hooks/use-cart';
import { formatPrice } from '../../utils/format-price';
import { InventoryError } from './OrderSummary';

const OrderItemList = ({
  inventoryError,
}: {
  inventoryError: InventoryError[];
}) => {
  const { cart } = useCart();

  return (
    <ul className="flex flex-col text-light-title">
      {cart.items.map((item) => {
        const error = inventoryError?.find(
          (e) => !!item.variant?.sku && e.sku === item.variant?.sku
        );

        return (
          <li
            key={item.id}
            className="flex gap-x-4 border-b border-light-divider py-3 first:pt-0 last:border-none last:pb-0"
          >
            <div className="h-[52px] w-[68px] shrink-0 rounded-md border border-light-divider bg-neutral-1 p-0.5">
              <div
                className="h-full w-full bg-cover"
                style={{ backgroundImage: `url(${item.thumbnail})` }}
              />
            </div>
            <div className="flex flex-1 flex-col justify-between gap-0.5 lg:flex-row lg:gap-4">
              <div className="flex flex-col gap-0.5">
                <div className="font-medium">{item.title}</div>
                <div>รหัสสินค้า: {item.variant?.sku ?? '-'}</div>
                <div>฿ {formatPrice(item.total || 0)}</div>
              </div>
              <div className="flex gap-0.5 lg:basis-32 lg:flex-col lg:items-end">
                <span>{item.quantity}x</span>{' '}
                {error && <ErrorMessage error={error} />}
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const ErrorMessage = ({ error }: { error: InventoryError }) => {
  let message = 'สินค้าหมด';

  if (error.contactSaleWarning) {
    message = 'กรุณาติดต่อฝ่ายขาย';
  }

  return <span className="w-max text-dust-red-7">{message}</span>;
};

export default OrderItemList;
