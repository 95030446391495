import { Address } from '@medusajs/medusa';
import { Radio, Tag } from 'antd';
import { sortBy } from 'lodash';
import React, { useState } from 'react';

import { formatAddressLine } from '../../helpers/address.helper';
import useCheckout from '../../hooks/useCheckout';
import useCountry from '../../hooks/useCountry';
import EditAddressFormModal from '../account/address/EditAddressFormModal';

const DeliveryAddressOptionList = () => {
  const { addresses } = useCheckout();
  const [editAddress, setEditAddress] = useState<Address | null>(null);

  const sortedAddresses = sortBy(addresses, (address) =>
    address.metadata?.isDefault ? 0 : 1
  );

  return (
    <ul className="flex flex-col gap-y-3">
      {sortedAddresses.map((address) => (
        <DeliveryAddressOption
          address={address}
          onEditAddress={(address) => setEditAddress(address)}
          key={address.id}
        />
      ))}
      <EditAddressFormModal
        selectedAddress={editAddress}
        resetAction={() => setEditAddress(null)}
      />
    </ul>
  );
};

const DeliveryAddressOption = ({
  address,
  onEditAddress,
}: {
  address: Address;
  onEditAddress: (address: Address | null) => void;
}) => {
  const { selectedShippingAddress, setSelectedShippingAddress } = useCheckout();
  const { getOption } = useCountry();

  const addressLine = formatAddressLine(address);

  const country = address.country_code
    ? getOption(address.country_code)?.label
    : '';

  return (
    <li key={address.id}>
      <Radio
        className="!m-0 !flex w-full !items-center rounded-md border border-light-border bg-neutral-1 !p-4 shadow-small"
        onChange={() => setSelectedShippingAddress(address)}
        checked={address.id === selectedShippingAddress?.id}
      >
        <div className="ml-2 flex flex-col gap-y-1 text-sm text-light-title">
          <div className="font-semibold">
            <span className="mr-2">
              {address.first_name} {address.last_name}
            </span>
            {address.metadata?.isDefault ? (
              <Tag color="blue">ที่อยู่หลัก</Tag>
            ) : null}
          </div>
          <div>{address.company}</div>
          <div>
            {addressLine} <br />
            {address.province}, {address.postal_code}, {country}
          </div>
          <div>{address.phone}</div>
          <div
            className="cursor-pointer text-primary-6 underline"
            onClick={() => onEditAddress(address)}
          >
            แก้ไข
          </div>
        </div>
      </Radio>
    </li>
  );
};

export default DeliveryAddressOptionList;
