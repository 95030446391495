import React from 'react';

import AccountDetail from '../account/AccountDetail';
import MainLayout from '../layout/MainLayout';
import DeliveryAddressSection from './DeliveryAddressSection';
import DeliveryType from './DeliveryType';
import OrderSummary from './OrderSummary';

const CheckoutPage = () => {
  return (
    <MainLayout logoOnly>
      <div className="mx-auto -mt-10 flex max-w-[1130px] flex-col p-6 pt-0 lg:flex-row">
        <div className="flex flex-1 flex-col gap-3">
          <AccountDetail editable={false} />
          <DeliveryType />
          <DeliveryAddressSection />
        </div>
        <div className="ml-0 mt-6 h-fit w-full shrink-0 grow-0 overflow-hidden rounded-md border border-light-divider bg-neutral-1 shadow-small lg:mt-0 lg:ml-6 lg:basis-[440px]">
          <OrderSummary />
        </div>
      </div>
    </MainLayout>
  );
};

export default CheckoutPage;
